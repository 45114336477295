.section-title {
    margin: 0 0 25px;
    color: #000;
    font-family: 'JioType';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.03em;

    animation: fadeInUp 1s;

    @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 24px;
    }
}

.section-title-para {
    margin: 0;
    color: #FFFFFF;
    font-family: 'JioType';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
}

.carousel {
    margin-bottom: 40px;

    .carousel-indicators{
        bottom: -60px;
        [data-bs-target] {
            margin: 0 8px;
            background: #000;
            width: 12px;
            height: 6px;
            text-align: center;
            border: none;
            border-radius: 25px;

            transition: all ease .5s;
        }
        .active{
            width: 20px;

            transition: all ease .5s;
        }
    }

    .carousel-inner {
        .carousel-item {
            overflow: hidden;
            border-radius: 20px;

            .bg-light {
                background: #DC0000 !important;
            }

            .circle {
                position: absolute;
                top: 50%;
                left: initial;
                right: -50px;
                background: #DC0000;
                width: 100vh;
                height: 100vh;
                transform: translateY(-50%);
                border-radius: 50%;
                z-index: -1;

                @include media-breakpoint-down(md) {
                    top: 0;
                    right: -50%;
                    width: 200%;
                    height: 200%;
                    transform: translateY(-50px);
                }

                @include media-breakpoint-down(sm) {}
            }

            .border-left {
                position: relative;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;
                z-index: 9;

                @include media-breakpoint-down(md) {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;

                    &::after {
                        display: none;
                    }
                }
            }

            .border-right {
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;

                img {
                    max-height: 264px;
                    border-top-right-radius: 20px;
                    border-bottom-right-radius: 20px;
                }

                @include media-breakpoint-down(md) {
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    img {
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }

            .slider-content {
                padding: 30px 25px;

                @include media-breakpoint-down(sm) {
                    padding: 0 25px 30px;
                }

                h3 {
                    margin: 0 0 20px 0;
                    font-family: 'JioType';
                    font-style: normal;
                    font-weight: 900;
                    font-size: 40px;
                    line-height: 40px;
                    letter-spacing: -0.03em;
                    color: #FFFFFF;
                }

                p {
                    margin-bottom: 40px;
                    font-family: 'JioType';
                    font-style: normal;
                    font-weight: 900;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.03em;
                    color: rgba(255, 255, 255, 0.78);
                }

                .view-more {
                    padding: 8px 20px;
                    color: #FFFFFF;
                    font-family: 'JioType';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.005em;
                    text-decoration: none;
                    background: #A80000;
                    border: 2px solid #A80000;
                    border-radius: 25px;

                    transition: all ease .5s;

                    &:hover {
                        background: transparent;
                        border: 2px solid #FFF;

                        transition: all ease .5s;
                    }
                }

                @include media-breakpoint-down(xl) {
                    h3 {
                        margin-bottom: 15px;
                        font-size: 34px;
                        line-height: 34px;
                    }

                    p {
                        margin-bottom: 20px;
                        font-size: 14px;
                        line-height: 16px;
                    }

                    .view-more {
                        padding: 8px 20px;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                @include media-breakpoint-down(lg) {
                    h3 {
                        margin-bottom: 10px;
                        font-size: 18px;
                        line-height: 18px;
                    }

                    p {
                        margin-bottom: 12px;
                        font-size: 12px;
                        line-height: 16px;
                    }

                    .view-more {
                        padding: 4px 10px;
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
            }

        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        i {
            font-size: 36px;
        }

        &:hover {
            opacity: 1;
        }
    }
}

.slick-slider {
    margin-bottom: 30px;
    
    .slick-list{
        margin: 0 -15px;
        .slick-slide {
            >div{
                margin: 0 15px;
            }
        }
    }
    
    .slick-dots {
        bottom: -50px;

        li {
            button {
                padding: 7px 0;

                &::before {
                    top: 7px;
                    left: 4px;
                    content: "";
                    width: 12px;
                    height: 6px;
                    background: #999999;
                    opacity: 1;
                    border-radius: 25px;

                    transition: all ease .2s;
                }
            }

            &.slick-active {
                button {
                    &::before {
                        left: 0;
                        width: 20px;
                        background: #000;

                        transition: all ease .2s;
                    }
                }
            }
        }
    }
}

.section {
    p {
        font-family: 'JioType';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #343434;
    }
}

.video {
    position: relative;

    a {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;

        img {
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
        }
    }
}

.leaders-speak {
    h3.para-title {
        color: #000;
        font-family: 'JioType';
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        line-height: 54px;

        @include media-breakpoint-down(md) {
            margin-top: 20px;
            font-size: 26px;
            line-height: 34px;
        }
    }

    p {
        color: #666666;
        font-family: 'JioType';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
    }
}