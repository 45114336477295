.login {
    .header,
    .footer{
        display: none;
    }
    main {
        margin: 0;
        // .vh-100{
        //     height: calc(100vh - 119px) !important;
        // }
        .login-img {
            width: 100%;
            height: 100vh;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: left;
            object-position: left;
        }

        .login-box {
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            padding: 50px;
            background-color: #fff;
            border-radius: 32px;

            -webkit-box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.1);
               -moz-box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.1);
                    box-shadow: 0px 0px 20px 3px rgba(0,0,0,0.1);

            h3 {
                margin-bottom: 25px;
                color: #141414;
                font-size: 30px;
                font-family: "JioType";
                font-weight: 900;

                span{
                    display: block;
                }
            }

            h4 {
                margin-bottom: 5px;
                color: #020132;
                font-size: 16px;
                font-family: "JioType";
                font-weight: 500;
                line-height: 24px;

                span{
                    display: block;
                }
            }

            p {
                color: #666666;
                font-size: 14px;
                font-family: "JioType";
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0px;

                a {
                    color: #3535F3;
                    text-decoration: none;

                    &:hover,
                    &:focus {
                        text-decoration: underline;
                    }
                }
            }

            .form-control {
                padding-left: 0;
                padding-right: 0;
                border: none;
                border-bottom: 2px solid rgba(0, 0, 0, .65);
                border-radius: 0;
            }

            ::-webkit-input-placeholder {
                /* Edge */
                color: #000;
                font-family: 'JioType';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.005em;
                opacity: .65;
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #000;
                font-family: 'JioType';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.005em;
                opacity: .65;
            }

            ::placeholder {
                color: #000;
                font-family: 'JioType';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.005em;
                opacity: .65;
            }

            .input-group-text {
                padding-right: 0;
                border: none;
                border-bottom: 2px solid rgba(0, 0, 0, .65);
                border-radius: 0;

                i {
                    color: #141414;
                }
            }

            .form-check {
                .form-check-label {
                    font-size: 14px;
                    font-family: "JioType";
                    font-weight: 500;
                }
            }

            .forgot{
                display: block;
                color: #3535F3;
                font-size: 14px;
                font-family: "JioType";
                font-weight: 700;
                text-decoration: none;

                &:hover{
                    text-decoration: underline;
                }
            }

            .terms{
                .MuiCheckbox-root{
                    margin-right: 10px;
                }

                span{
                    font-size: 14px;
                    font-family: "JioType";
                    font-weight: 700;

                    a{
                        color: #3535F3;
                        text-decoration: none;
    
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
            }

            .count{
                .timer{
                    display: block;
                    color: #77777B;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-decoration: none;
                }

                .link-text{
                    display: block;
                    color: #525252;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 24px;
                    text-decoration: none;
                }
            }

            @include media-breakpoint-down(lg) {
                padding: 50px 30px;
            }
        }

        @include media-breakpoint-down(md) {
            margin: 100px 0 170px;
        }
    }
}

.otp-field {
	flex-direction: row;
	column-gap: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	input {
        padding: 10px;
		height: 20px;
		width: 20px;
		border-radius: 6px;
		outline: none;
		font-size: 14px;
        font-weight: 700;
		text-align: center;
		border: none !important;
        border-bottom: 1px solid #000 !important;
        border-radius: 0;
		&:focus {
			box-shadow: none;
		}
	}
    fieldset{
        border: none;
    }
}
.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
	display: none;
}
