

.consent-status{
    h4{
        margin: 0 0 10px;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
    }

    .status-box{
        padding: 15px;
        min-width: 170px;
        border: 1px solid #CCE9DC;
        border-radius: 10px;

        &.c1{
            background: #DBF8EB;
            border-color: #CCE9DC;
        }

        &.c2{
            background: #D6F9FF;
            border-color: #CAE6EB;
        }

        &.c3{
            background: #FFE7D4;
            border-color: #F8DAC4;
        }

        &.c4{
            background: #FDE1DA;
            border-color: #EFD3CC;
        }
    
        label{
            margin-bottom: 15px;
            color: #141414;
            font-size: 14px;
            font-weight: 900;
            line-height: 16px;
            text-transform: uppercase;

            svg{
                margin-right: 10px;
            }
        }
    
        h1{
            margin: 0;
            color: #333;
            font-size: 40px;
            font-weight: 500;
            line-height: 40px;
    
            span{
                display: block;
                color: #999;
                font-size: 13px;
                font-weight: 700;
                line-height: 15px;
            }
        }
    }
}

.meta-box{
    margin-bottom: 20px;
    background: #F7F9FD;
    border: 1px solid #A6CDCD;
    border-radius: 6px;

    .meta-head{
        padding: 10px 15px;
        border-bottom: 1px solid #A6CDCD;;

        h4{
            margin: 0;
            color: #000;
            font-size: 13px;
            font-weight: 700;
            line-height: 18px;
            span{
                display: block;
                color: #666;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    .meta-body{
        padding: 10px 15px;
        background: #E3F0FD;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .meta-info{
            margin: 0;
            padding: 0;
            list-style: none;

            li{
                padding: 10px 0;
                border-bottom: 1px solid #ccc;

                &:last-child{
                    border-bottom: none;
                }

                h4{
                    margin: 0;
                    color: #000;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 18px;
                    span{
                        display: block;
                        color: #666;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                p{
                    margin: 0;
                    color: #000;
                    font-size: 13px;
                    font-weight: 700;
                    line-height: 18px;
                }
            }
        }
    }
}

.meta-search-box{
    padding: 15px;
    background: #F5F5F5;
    border-radius: 10px;

    &.customized-box{
        position: relative;
        min-width: 340px;
        background: transparent;
        border: 1px solid #E5E5E5;
        border-radius: 15px;
    }

    .refresh{
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

.heading{
    margin: 0 0 10px;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
}

.meta-search-list{
    padding: 15px;
    max-height: 645px;
    overflow-y: auto;
    border: 1px solid #E5E5E5;
    border-radius: 10px;

    ul{
        margin: 0;
        padding: 0;
        list-style: none;

        li{
            position: relative;
            padding: 8px 50px 8px 0;
            border-bottom: 1px solid #E5E5E5;

            &:last-child{
                border: none;
            }

            .badge{
                position: absolute;
                top: 14px;
                right: 0;
                color: #FFF;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                background: #0F3CC9;
                border-radius: 6px;
            }
        }
    }
}