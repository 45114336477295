

.MuiDialog-paper{
    .MuiDialogContent-root{
        padding: 0;

        .MuiTypography-root{
            position: relative;
            margin-bottom: 0;

            h3{
                margin: 0;
                font-size: 30px;
                font-weight: 900;
                line-height: 36px;
            }

            p{
                margin: 0;
                color: #020132;
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
            }
        }
    }
}

.custom-diaog-sm{
    .MuiDialog-container{
        .MuiPaper-root{
            width: 100%;
            max-width: 460px;
        }
    }
}

.MuiDialog-root{
    .MuiDialog-container{
        .MuiPaper-root{
            .MuiDialogContent-root{
                padding: 30px;

                .pop-title{
                    margin: 0;
                    color: #141414;
                    font-size: 30px;
                    font-weight: 900 !important;
                    line-height: 32px;
                }
                .pop-title-sub{
                    color: #141414;
                    font-size: 16px;
                    font-weight: 500 !important;
                    line-height: 32px;
                }

                .data-box{
                    padding: 20px;
                    display: inline-block;
                    text-align: center;
                    text-decoration: none;
                    // background: #E7EBF8;
                    border-radius: 20px;

                    .icon{
                        margin: 0 auto 10px;
                        width: 57px;
                        height: 57px;
                        line-height: 57px;
                        background: #FFF;
                        text-align: center;
                        border-radius: 50%;
                    }

                    h4{
                        margin: 0 0 10px;
                        color: #000;
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 22px;
                    }

                    p{
                        margin: 0;
                        color: #333;
                        font-size: 18px;
                        font-weight: 300;
                        line-height: 28px;
                    }
                }
            }
        }
    }
}

.terms{
    .MuiCheckbox-root{
        margin-right: 10px;
    }

    span{
        font-size: 14px;
        font-family: "JioType";
        font-weight: 700;

        a{
            color: #3535F3;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }
    }
}

h3 {
    margin-bottom: 25px;
    color: #141414;
    font-size: 30px;
    font-family: "JioType";
    font-weight: 900;

    span{
        display: block;
    }
}

h4 {
    margin-bottom: 5px;
    color: #020132;
    font-size: 16px;
    font-family: "JioType";
    font-weight: 500;
    line-height: 24px;

    span{
        display: block;
    }
}

p {
    color: #666666;
    font-size: 14px;
    font-family: "JioType";
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;

    a {
        color: #3535F3;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.count{
    .timer{
        display: block;
        color: #77777B;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-decoration: none;
    }

    .link-text{
        display: block;
        color: #525252;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-decoration: none;
    }
}

.note-box{
    padding: 20px;
    background: #F5F5F5;
    border-radius: 6px;

    .note-label{
        margin: 0;
        color: #666;
        font-size: 12px;
        font-weight: 500;
        line-height: 23px;

        span{
            display: block;
            color: #141414;
            font-size: 14px;
            font-weight: 700;
        }
    }

    .note-list{
        margin: 0;
        padding: 0 0 0 20px;

        li{
            margin: 0 0 10px;
            color: #141414;
            font-size: 12px;
            font-weight: 500;
        }
    }
}