.footer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 0;
    background: #F5F5F5;
    border-top: 1px solid #e0e0e0;
    p{
        margin: 0;
        color: rgba(0, 0, 0, 0.65);;
        font-family: 'JioType';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;

        @include media-breakpoint-down(md){
            margin-bottom: 10px;
            text-align: center;
        }
    }
    a{
        position: relative;
        margin: 0 1px 0 0;
        padding: 0 10px;
        color: rgba(0, 0, 0, 0.65);
        font-family: 'JioType';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.005em;
        text-decoration: none;
        &:hover,
        &:focus{
            text-decoration: underline;
        }
        &::before{
            position: absolute;
            top: 0;
            right: -1px;
            content: "";
            width: 1px;
            height: 16px;
            background: #E0E0E0;
        }
        &:last-child{
            &::before{
                display: none;
            }
        }
    }
}