.custom-accordion{
    .accordion-item{
        position: relative;
        
        &.br20{
            border-radius: 20px;
        }

        .ah-buttons{
            position: absolute;
            top: 16px;
            right: 50px;
            z-index: 9;
        }

        .accordion-header{

            &.hbr20{
                button{
                    border-radius: 20px;
                }
            }

            .accordion-button{
                padding: 25px 1.25rem;
                color: #141414;
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                box-shadow: none;

                &[aria-expanded="true"]{
                    // background: #FFF;
                    box-shadow: none;
                }
            }
        }
    }
    
}

.pop-custom-accordion{
    border: none;

    .accordion-item{
        padding: 12px;
        border: none;
        border-radius: 1;

        .accordion-header{
            border-bottom: 1px solid #ccc;
            .accordion-button{
                padding: 8px 0;
                color: #141414;
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                box-shadow: none;

                &[aria-expanded="true"]{
                    // background: #FFF;
                    box-shadow: none;
                }
            }
        }

        .accordion-body{
            .pro-box{
                padding: 0;
                border: 1px solid #A6CDCD;
                border-radius: 6px;
            }
        }
    }
    
}

.customized-theme-accordion{
    border: none;

    .accordion-item{
        margin-bottom: 1rem;
        padding: 0;
        border: 1px solid #ccc;
        border-radius: 8px;

        &:last-child{
            margin-bottom: none;
        }

        .accordion-header{
            border-bottom: none;
            .accordion-button{
                padding: 12px;
                color: #000;
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                border-radius: 8px;
                box-shadow: none;

                &[aria-expanded="true"]{
                    // background: #FFF;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    box-shadow: none;
                }
            }
        }

        .accordion-body{
            padding: 12px;
            border-top: 1px solid #ccc;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    
}