/* CSS for smaller toggle switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjust the width */
  height: 20px; /* Adjust the height */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Smaller size */
  width: 16px; /* Smaller size */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3; /* Change color when checked */
}

input:checked + .slider:before {
  transform: translateX(20px); /* Adjust movement for smaller size */
}
